/* Projects.css */
.custom-scrollbar {
  overflow-y: auto; /* Enable vertical scrolling */
  overflow-x: auto; /* Enable horizontal scrolling */
}

/* Style the scrollbar for Webkit browsers (Chrome, Safari) */
.custom-scrollbar::-webkit-scrollbar {
  width: 12px; /* Width of the vertical scrollbar */
  height: 12px; /* Height of the horizontal scrollbar */
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #3e3e3e !important; /* Background of the track */
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #0d0d0d !important; /* Color of the scrollbar handle */
  border-radius: 6px; /* Rounded corners for the handle */
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555 !important; /* Color of the scrollbar handle on hover */
}

/* Optional: Styles for the horizontal scrollbar */
.custom-scrollbar::-webkit-scrollbar-thumb:horizontal {
  background: #0d0d0d !important; /* Color of the horizontal scrollbar handle */
  border-radius: 6px; /* Rounded corners for the horizontal handle */
}

.custom-scrollbar::-webkit-scrollbar-thumb:horizontal:hover {
  background: #555 !important; /* Color of the horizontal scrollbar handle on hover */
}
